import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Content from '../components/Content';
import Layout from './../components/layout';
import * as StyleVars from './../cssVariables';
import SignUp from './../components/SignUp';
import SEO from '../components/seo';

const Wrapper = styled.div`
  padding: 48px 40px 118px;
  margin: 0 auto -70px;
  max-width: 736px;
  color: white;
  line-height: 2;

  p {
    font-size: 18px;
    font-weight: 300;
  }

  @media (${StyleVars.mediaTablet}) {
    width: 90%;
    margin: 0;
    padding: 0;
  }

  @media (${StyleVars.mediaMobile}) {
    width: 100%;
  }
`;

const Page = styled.div`
  background-color: #03030b;
`;

const ContentText = styled.div`
  width: 70%;
  margin: 0 auto;

  @media (${StyleVars.mediaTablet}) {
    width: 90%;
  }

  @media (${StyleVars.mediaMobile}) {
    width: 100%;
  }
`;

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark;

  return (
    <Layout>
      <Page>
        <SEO title={frontmatter.title} />
        <Content>
          <ContentText>
            <Wrapper dangerouslySetInnerHTML={{ __html: html }} />
          </ContentText>
        </Content>
      </Page>
      <SignUp />
    </Layout>
  );
};
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

export default Template;
